@font-face {
  font-family: "Paralucent";
  src: url("./fonts/Paralucent-Medium.woff2") format("woff2");
  src: url("./fonts/Paralucent-Medium.woff") format("woff");
}

@font-face {
  font-family: "Paralucent Condensed";
  src: url("./fonts/paralucent-condensed-medium.woff2") format("woff2");
  src: url("./fonts/paralucent-condensed-medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Paralucent", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-svg-wrapper,
.icon-svg-wrapper svg {
  height: 100%;
}

/* We don't want default blue as focus. Set own outline in component (or here) if needed. */
input:focus, textarea:focus {
  outline: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* Common colors in all themes */
  --color-bgDrawing: 35 66 80;
  --color-textDrawing: 233 236 237;
}

.theme-yellow-default {
  /* Brand colors: Yellow and dark grey */
  --color-brandPrimary: 254 182 43; /* Sidebar color (yellow) */
  --color-brandSecondary: 26 31 32; /* Main content color (dark-grey) */
  --color-brandTertiary: 254 182 43; /* Main yellow */

  --color-brandSecondaryDark: 25 25 25; /* Darker grey, used in selected object rows */
  --color-brandSecondaryLight:61 75 77; /* Lighter grey */
  --color-brandPrimaryDark:228 114 31; /* Darker yellow, logo orange */
  --color-brandPrimaryLight: 254 196 69; /* Lighter yellow */

  --color-brandText: 255 255 255; /* White */
  --color-brandTextSecondary: 255 255 255; /* White */


  /* Background colors. First 2 are "page-layout-colors": Dark and light grey. */
  --color-bgPrimary: 26 31 32; /* Upper part of page (ObjectHeader), look --color-brandSecondary */
  --color-bgSecondary: 238 238 238; /* Lower part of page, very light grey (almost white) */
  /* These 2 are mostly used with content on page: */
  --color-bgContentPrimary: 245 245 245; /* Document color itself */
  --color-bgContentSecondary: 255 255 255; /* Document cards etc. */

  /* New color combinations needed in templatesEditor, we need these colors for sidebar: */
  --color-bgList: 50 62 63; /* List bg color */
  --color-bgListContent: 61 75 77; /* List item bg color */

  --color-bgGray: 245 245 245;  /* Some kind of special case, this is same in every theme */


  /* Text colors */
  --color-textPrimary: 255 255 255; /* Main text color (white)*/
  --color-textSecondary: 174 184 185; /* "Darker" text color */
  --color-textTertiary: 61 75 77; /* "More darker" text color */
  --color-textSuccess: 255 255 255; /* Text color in "green" buttons */

  --color-textContentPrimary:  25 25 25; /* We need this for default-theme. To show dark text on light bg. For dark and light themes this is same than textPrimary. */

  --color-textLinkColor: 30 58 138; /* Blue-900 */

  /* State colors, usually same in every theme */
  --color-disabled: 101 110 111; /* Light grey */
  --color-success: 43 178 113; /* Color of "green" */
  --color-inprogress: 254 182 43; /* Yellow */
  --color-notstarted: 61 75 77; /* Grey */
  --color-error: 232 70 16; /* Red */

  --color-hoverBorder: 43 178 113; /* Green, success */
}

.theme-yellow-dark {
  /* Brand colors: Yellow and dark grey */
  --color-brandPrimary: 234 195 31; /* Sidebar color (yellow) */
  --color-brandSecondary: 17 35 43; /* Main content color (dark-grey) */
  --color-brandTertiary: 234 195 31; /* Main yellow */

  --color-brandSecondaryDark: 13 27 33; /* Darker grey, used in selected object rows */
  --color-brandSecondaryLight:35 66 80; /* Lighter grey */
  --color-brandPrimaryDark:192 127 0; /* Darker yellow */
  --color-brandPrimaryLight: 249 211 53; /* Lighter yellow */

  --color-brandText: 255 255 255; /* White */
  --color-brandTextSecondary: 255 255 255; /* White */


  /* Background colors: Very dark and dark grey */
  --color-bgPrimary: 17 35 43; /* Upper part of page (ObjectHeader), look --color-brandSecondary */
  --color-bgSecondary: 35 66 80; /* Lower part of page, lighter dark */

  --color-bgContentPrimary: 40 73 88; /* Document color itself */
  --color-bgContentSecondary: 17 35 43; /* Document cards etc. */

  /* New color combinations needed in templatesEditor, we need these colors for sidebar: */
  --color-bgList: 35 66 80; /* List bg color */
  --color-bgListContent: 40 73 88; /* List item bg color */

  --color-bgGray: 245 245 245;  /* Some kind of special case, this is same in every theme */


  /* Text colors */
  --color-textPrimary: 255 255 255; /* Main text color (white)*/
  --color-textSecondary: 159 185 189; /* "Darker" text color */
  --color-textTertiary: 35 66 80; /* "More darker" text color */
  --color-textSuccess: 255 255 255; /* Text color in "green" buttons */

  --color-textContentPrimary: 255 255 255;

  --color-textLinkColor: 147 197 253; /* Blue-300 */

  /* State colors, usually same in every theme */
  --color-disabled: 96 107 113; /* Light grey */
  --color-success: 43 178 113; /* Color of "green" */
  --color-inprogress: 234 195 31; /* Yellow */
  --color-notstarted: 35 66 80; /* Grey */
  --color-error: 251 110 64; /* Red */

  --color-hoverBorder: 43 178 113; /* Green, success */
}

.theme-yellow-darker {
  /* Brand colors: Yellow and dark grey */
  --color-brandPrimary: 13 27 33; /* Sidebar color (dark-grey) */
  --color-brandSecondary: 234 195 31; /* Main content color (yellow) */
  --color-brandTertiary: 234 195 31; /* Main yellow */

  --color-brandSecondaryDark: 13 27 33; /* Darker grey, used in selected object rows */
  --color-brandSecondaryLight:35 66 80; /* Lighter grey */
  --color-brandPrimaryDark:192 127 0; /* Darker yellow */
  --color-brandPrimaryLight: 249 211 53; /* Lighter yellow */

  --color-brandText: 255 255 255; /* White */
  --color-brandTextSecondary: 13 27 33; /* Dark-grey */


  /* Background colors: Very dark and dark grey */
  --color-bgPrimary: 17 35 43; /* Upper part of page (ObjectHeader), look --color-brandSecondary */
  --color-bgSecondary: 35 66 80; /* Lower part of page, lighter dark */

  --color-bgContentPrimary: 40 73 88; /* Document color itself */
  --color-bgContentSecondary: 17 35 43; /* Document cards etc. */

  /* New color combinations needed in templatesEditor, we need these colors for sidebar: */
  --color-bgList: 35 66 80; /* List bg color */
  --color-bgListContent: 40 73 88; /* List item bg color */

  --color-bgGray: 245 245 245;  /* Some kind of special case, this is same in every theme */


  /* Text colors */
  --color-textPrimary: 255 255 255; /* Main text color (white)*/
  --color-textSecondary: 159 185 189; /* "Darker" text color */
  --color-textTertiary: 35 66 80; /* "More darker" text color */
  --color-textSuccess: 255 255 255; /* Text color in "green" buttons */

  --color-textContentPrimary: 255 255 255;

  --color-textLinkColor: 147 197 253; /* Blue-300 */

  /* State colors, usually same in every theme */
  --color-disabled: 96 107 113; /* Light grey */
  --color-success: 43 178 113; /* Color of "green" */
  --color-inprogress: 234 195 31; /* Yellow */
  --color-notstarted: 35 66 80; /* Grey */
  --color-error: 251 110 64; /* Red */

  --color-hoverBorder: 43 178 113; /* Green, success */
}

.theme-yellow-light {
  /* Brand colors: Yellow and dark grey */
  --color-brandPrimary: 234 195 31; /* Sidebar color (yellow) */
  --color-brandSecondary: 17 35 43; /* Main content color (dark-grey) */
  --color-brandTertiary: 234 195 31; /* Main yellow */

  --color-brandSecondaryDark: 245 245 245; /* Almost white, used in selected object rows */
  --color-brandSecondaryLight:35 66 80; /* Lighter grey */
  --color-brandPrimaryDark:192 127 0; /* Darker yellow */
  --color-brandPrimaryLight: 249 211 53; /* Lighter yellow */

  --color-brandText: 255 255 255; /* White */
  --color-brandTextSecondary: 255 255 255; /* White */


  /* Background colors: Light grey and very light grey */
  --color-bgPrimary:  233 236 237; /* Upper part of page (ObjectHeader), light grey */
  --color-bgSecondary:  233 236 237; /* Lower part of page, very light grey (almost white), look --color-bgGray*/
  --color-bgContentPrimary: 245 245 245; /* Document color itself */
  --color-bgContentSecondary: 255 255 255; /* Document cards etc. */

  /* New color combinations needed in templatesEditor, we need these colors for sidebar: */
  --color-bgList: 233 236 237; /* List bg color */
  --color-bgListContent: 255 255 255; /* List item bg color */

  --color-bgGray: 245 245 245;  /* Some kind of special case, this is same in every theme */


  /* Text colors */
  --color-textPrimary: 17 35 43;  /* --color-brandSecondary */
  --color-textSecondary: 131 140 144;  /* "Darker" text color */
  --color-textTertiary: 35 66 80;  /* "More darker" text color */
  --color-textSuccess: 255 255 255;  /* Text color in "green" buttons */

  --color-textContentPrimary: 17 35 43;

  --color-textLinkColor: 30 58 138; /* Blue-900 */

  /* State colors, usually same in every theme */
  --color-disabled: 96 107 113; /* Light grey */
  --color-success: 43 178 113; /* Color of "green" */
  --color-inprogress: 234 195 31; /* Yellow */
  --color-notstarted: 35 66 80; /* Grey */
  --color-error: 251 110 64; /* Red */

  --color-hoverBorder: 195 200 202; /* Light grey */
}

.theme-yellow-default .navbar-logo, .theme-yellow-dark .navbar-logo, .theme-yellow-light .navbar-logo {
  background: url("utils/icons/logo-kotopro.svg") center no-repeat;
}
.theme-yellow-darker .navbar-logo {
  background: url("utils/icons/logo-kotopro-yellow.svg") center no-repeat;
}

.login-logo-expanded {
  background: url("utils/icons/logo-vision-singleline.svg") center no-repeat;
  background-size: contain;
}

.theme-yellow-default .navbar-logo-expanded, .theme-yellow-dark .navbar-logo-expanded, .theme-yellow-light .navbar-logo-expanded {
  background: url("utils/icons/logo-vision-expanded.svg") center no-repeat;
  background-size: contain;
}
.theme-yellow-darker .navbar-logo-expanded {
  background: url("utils/icons/logo-vision-expanded-yellow.svg") center no-repeat;
  background-size: contain;
}

.theme-yellow-default .border-add-button,
.theme-yellow-light .border-add-button {
  /*@apply border border-textContentPrimary/25;*/
}

/* background transition from secondary to light-grayish */
.document-head-bg {
  background: linear-gradient(rgb(var(--color-bgPrimary)) 8em, rgb(var(--color-bgSecondary)) 8em, rgb(var(--color-bgSecondary)));
}
.phone-card-bg {
  background: linear-gradient(transparent 3em, rgb(var(--color-brandSecondaryLight)) 3em, rgb(var(--color-brandSecondaryLight)) );
}

.DayPicker-error input {
  color: rgb(var(--color-error));
}

.selectedCustom {
  position: relative;

  background-color: rgb(var(--color-brandPrimary)) !important;
  color: rgb(var(--color-textPrimary)) !important;
  border: 0 !important;
}

.datafield:-webkit-autofill,
.datafield:-webkit-autofill:hover, 
.datafield:-webkit-autofill:focus, 
.datafield:-webkit-autofill:active{
    box-shadow: 0 0 0 30px rgb(var(--color-bgContentPrimary)) inset !important;
    -webkit-box-shadow: 0 0 0 30px rgb(var(--color-bgContentPrimary)) inset !important;
}

.pageBreak {
    display: flex;
    flex-direction: row;
  }
  .pageBreak:before{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  
  .pageBreak:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }

  .pageBreak:before {
      margin-left: 5px;
    margin-right: 60px
  }
  .pageBreak:after {
    margin-left: 60px;
    margin-right: 5px;
  }

   /* When the checkbox is checked and not disabled, add a yellow background */
   .checkmarkContainer input:not(:disabled):checked ~ .checkmark {
    background-color: rgb(var(--color-brandTertiary));
  }

  .checkmarkContainer input:disabled ~ .checkmark {
    cursor: not-allowed;
  }
  
  /* Show the checkmark when checked */
  .checkmarkContainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkmarkContainer .checkmark:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .switch {
    display: inline-block;
    height: 26px;
    position: relative;
    width: 60px;
    min-width: 60px;
  }
  
  .switch input {
    display:none;
  }
  
  .slider {
    background-color: lightgray;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  
  .slider:before {
    background-color:white;
    bottom: 2px;
    content: "";
    height: 20px;
    left: 4px;
    position: absolute;
    width: 20px;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: rgba(43, 178, 113, 1)
  }


  input:checked + .slider:before {
    transform: translateX(31px);
    background-color: white;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .navigationViewContainer {
    /*Left panel has 1/4(25%) of navigationViewContainer width*/
    /* 10rem is child content width*/
    /* We divide by two because child content is centered*/
    --width-leftPanel: calc((25% - 10rem) / 2);
  }

  .rightContent {
    margin-right: var(--width-leftPanel);
  }

.todayCustom {
  position: relative;

  background-color: transparent;
  color: rgb(var(--color-textPrimary)) !important;
  border: 1px solid rgb(var(--color-textPrimary)) !important;
}

.rdp-button:hover:not([disabled]) {
  background-color: rgb(var(--color-bgContentPrimary)) !important;
  color: rgb(var(--color-textContentPrimary)) !important;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

.ql-container {
  font-size: 15px !important;
}

.markdownView ol {
  padding-left: 15px;
  list-style: decimal;
}

.markdownView ul {
  padding-left: 15px;
  list-style: disc;
}

.markdownView pre {
  display: block;
  padding: 10.5px;
  margin: 0 0 11px;
  font-size: 15px;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* Creating custom tooltip is recommended here.
  https://github.com/chartjs/Chart.js/issues/622#issuecomment-938548768 
  And adding tailwind classes on fly dont seem to work. So just add styles here.
*/
#chartjs-tooltip {
  background: white;
  border-radius: 5px;
  z-index: 50;
  font-size: 12px;
  padding: 0.5em;
}

.leaflet-container {
  height: 300px;
  width: 100%;
}

/* Align beacon main view to top right corner (and only when it's not in mobile) */
.BeaconContainer:not(.is-mobile) {
    position: absolute !important;
    /*top: calc(56px) !important; This is on the way on top. We automatically open beacon for trial-users*/
    right: calc(20px) !important;
}
/* Hide our close btn on mobile */
.BeaconContainer.is-mobile {
    z-index: 9999 !important;
}

.modal-content {
  background-color: rgb(var(--color-bgSecondary));
  color: rgb(var(--color-textContentPrimary));
  fill: rgb(var(--color-textContentPrimary));
}

.modal-header {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 500;
  border-color: rgba(var(--color-textPrimary), 0.25);
}

.modal-footer {
  background-color: rgb(var(--color-bgContentPrimary));
  color: rgb(var(--color-textContentPrimary));
  border-color: rgba(var(--color-textPrimary), 0.25);
}

@media only screen and (max-width: 390px)  {
    .fill-available {
        max-width: -webkit-fill-available;
        max-width: fill-available;
        max-width: -moz-available;
    }
  }

/* For some reason these don't work correctly when set in className. Just use from here now. */
.flowing-marking-infobox::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border: 12px solid transparent;
  border-top-color: rgb(var(--color-bgDrawing));
  border-bottom: 0;
  left: 20px;
  bottom: -11px;
}

/* Seems impossible to style this element any other way */
.selectIndicatorsContainer div {
  padding: 0px;
}

@media only screen and (min-width: 768px)  {
  .selectIndicatorsContainer div {
    padding: 8px;
  }
}
blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
  margin: 5px 0px;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.overflow-wrap-anywhere {
    overflow-wrap: break-word; /* For compatability with older browsers */
    overflow-wrap: anywhere;
}

/* iOS-specific styles, otherwise modal with overflow-x-hidden causes modal to be unscrollable on y-axis as well */
@supports (-webkit-overflow-scrolling: touch) {
  .modal-content {
    -webkit-overflow-scrolling: touch;
    max-height: 95vh;
    overflow-y: scroll;
  }
}